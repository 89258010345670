.App {
  text-align: center;




  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin            : 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }



  .partners_main_box {
    -webkit-clip-path: polygon(50% 0, 100% 19%, 100% 80%, 51% 100%, 0 81%, 0 18%);
    clip-path        : polygon(50% 0, 100% 19%, 100% 80%, 51% 100%, 0 81%, 0 18%);
  }




}